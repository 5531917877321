@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'material.scss';
@import '../node_modules/@freshfox/ng-core/styles/main';
@import '../node_modules/@freshfox/ng-auth/styles/auth';

:root {
  --ff-auth-logo-size: 155px;
}
