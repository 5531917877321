@use '@angular/material' as mat;

@use './material-theme' as theme;

@include mat.core();

// Apply the light theme by default
@include mat.core-theme(theme.$light-theme);
@include mat.form-field-theme(theme.$light-theme);
@include mat.button-theme(theme.$light-theme);
@include mat.input-theme(theme.$light-theme);
@include mat.table-theme(theme.$light-theme);
@include mat.paginator-theme(theme.$light-theme);
@include mat.button-toggle-theme(theme.$light-theme);
@include mat.input-theme(theme.$light-theme);
@include mat.dialog-theme(theme.$light-theme);
@include mat.menu-theme(theme.$light-theme);
@include mat.select-theme(theme.$light-theme);
@include mat.slider-theme(theme.$light-theme);
@include mat.chips-theme(theme.$light-theme);
@include mat.slide-toggle-theme(theme.$light-theme);
@include mat.progress-spinner-theme(theme.$light-theme);
@include mat.tabs-theme(theme.$light-theme);
@include mat.autocomplete-theme(theme.$light-theme);
@include mat.list-theme(theme.$light-theme);
@include mat.datepicker-theme(theme.$light-theme);
@include mat.progress-spinner-theme(theme.$light-theme);
@include mat.checkbox-theme(theme.$light-theme);

// For ngx-time-picker
@include mat.toolbar-theme(theme.$light-theme);

body.dark {
  @include mat.core-color(theme.$dark-theme);
  @include mat.form-field-color(theme.$dark-theme);
  @include mat.button-color(theme.$dark-theme);
  @include mat.input-color(theme.$dark-theme);
  @include mat.table-color(theme.$dark-theme);
  @include mat.paginator-color(theme.$dark-theme);
  @include mat.button-toggle-color(theme.$dark-theme);
  @include mat.input-color(theme.$dark-theme);
  @include mat.dialog-color(theme.$dark-theme);
  @include mat.menu-color(theme.$dark-theme);
  @include mat.select-color(theme.$dark-theme);
  @include mat.slider-color(theme.$dark-theme);
  @include mat.chips-color(theme.$dark-theme);
  @include mat.slide-toggle-color(theme.$dark-theme);
  @include mat.progress-spinner-color(theme.$dark-theme);
  @include mat.tabs-color(theme.$dark-theme);
  @include mat.autocomplete-color(theme.$dark-theme);
  @include mat.list-color(theme.$dark-theme);
  @include mat.datepicker-color(theme.$dark-theme);
  @include mat.progress-spinner-color(theme.$dark-theme);
  @include mat.checkbox-color(theme.$dark-theme);

  // For ngx-time-picker
  @include mat.toolbar-color(theme.$dark-theme);
}

.mat-select-search-inner {
  background-color: white;
}
